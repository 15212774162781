.add_btn{
    width: 50px;
    height: 50px;
    color: #fff;
    font-weight: bolder;
    font-size :40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

form{

    .form-control{
        background-color: transparent;
        &:focus{
            outline: none;
            box-shadow: none; 
            border-color: rgb(206, 206, 206); 
        }
    }


}


.main_outer_con{
    display: flex;
    justify-content: center;
    position: relative;
    // padding-bottom: 50px;

    .search_sec{
        // width: 100%;
        // height: 50px;
        // padding: 5px 0px;
        z-index: 99;
        // position: fixed !important;
        // top: 56px;
        display: flex;
        // margin-top: 50px;
        align-items: center;
        justify-content: center;



    }

    .cards_display{
        // width: 94.5%;
        // padding-left: 25px;
        margin-top: 50px;
    }
}

.btn-close:focus{
    box-shadow: none;
}


.theme_styles{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0px 8px 15px 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.note_card{
    width: 300px;
    padding-bottom: 10px;
    cursor: pointer;
    
    .card-text{
        // height: 85%;
        height: 220px;
        overflow: hidden;
        // background-color: aliceblue;
    }

}
 pre{
    white-space: pre-wrap; 
    word-break: break-word;
 }

.btn-close:focus{
    box-shadow: none !important;
    border: none;
    border-color: transparent;
}

@media screen and (max-width: 913px){

    .main_outer_con{
        justify-content: normal;
        .cards_display{
            width: 100%;
            padding: 15px;
            margin-top: 50px;

            .note_card{
                // width: 35%;
                padding-bottom: 10px;
                
                .card-text{
                    // height: 85%;
                    // max-height: 36vh;
                    overflow: hidden;
                    // background-color: aliceblue;
                }
            
            }

        }
    }
}

@media screen and (max-width: 415px){

    .search_sec{
        .note_search{
            width: 90% !important;
            // background-color: pink;
        }

    }

}

@media screen and (max-width: 387px){

    .theme_styles{
        width: 45px;
        height: 45px;
        border-radius: 50%;
        margin: 0px 8px 20px 8px;
        cursor: pointer
    }

    .note_card{
        width: 95%;
        padding-bottom: 10px;
        
        .card-text{
            // height: 85%;
            font-size: 15px;
            overflow: hidden;
            // background-color: aliceblue;
        }
    
    }
    .search_sec{
        .note_search{
            width: 90% !important;
            // background-color: pink;
        }

    }

}