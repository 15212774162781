@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@600&family=Playpen+Sans:wght@500&family=Roboto:ital@1&display=swap");
.main_ledger_component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.main_ledger_component .ledger_header_block {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
}

.main_ledger_component .ledger_body_block {
  display: flex;
  flex-direction: column;
}

.main_ledger_component .body_details {
  height: 70vh;
}

.main_ledger_component .body_details .inner_body {
  width: 50%;
}

.main_ledger_component .body_details .inner_body .logo_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 15px;
}

.main_ledger_component .body_details .inner_body .logo_container img {
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #F1F0E8;
}

.main_ledger_component .body_details .inner_body .logo_container p {
  margin-top: 5px;
  font-size: 18px;
  font-weight: bold;
  font-family: 'Oswald', sans-serif;
  font-family: 'Playpen Sans', cursive;
  font-family: 'Roboto', sans-serif;
}

.main_ledger_component .body_details .inner_body .logo_container div {
  width: 180px;
  height: 150px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_ledger_component .body_details .inner_body .logo_container div .img_1 {
  position: absolute;
  margin-right: 90px;
}

.main_ledger_component .body_details .inner_body .logo_container div .img_2 {
  position: absolute;
  margin-left: 90px;
}

.main_ledger_component .body_details .inner_body .details_con .blocks {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main_ledger_component .body_details .inner_body .details_con .blocks h4 {
  text-align: center;
  font-size: 1.2rem;
}

.main_ledger_component .body_details .inner_body .details_con .blocks .principal_amt {
  position: relative;
}

.main_ledger_component .body_details .inner_body .details_con .blocks .principal_amt span {
  position: absolute;
  font-size: 12px;
  top: 0px;
}

.main_ledger_component .body_details .inner_body .details_con .blocks .amt_name {
  width: 70%;
  border-right: none;
  display: flex;
  align-items: flex-start;
  padding-left: 2rem;
}

.main_ledger_component .body_details .inner_body .details_con .blocks .amt_num {
  width: 30%;
  display: flex;
  padding-right: 2rem;
}

.main_ledger_component .body_details .inner_body .details_con .btn_block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main_ledger_component .body_details .inner_body .details_con .btn_block div {
  width: 90%;
  display: flex;
  justify-content: center;
}

.main_ledger_component .body_details .inner_body .details_con .btn_block div button {
  width: 100%;
  font-weight: 600;
}

.main_ledger_component .body_details .inner_body .details_con .btn_block div .add_trans {
  background-color: #0d6efd;
  background-color: #00A9FF;
  color: #fff;
}

.main_ledger_component .body_details .inner_body .details_con .btn_block div .view_history {
  background-color: #D0D4CA;
}

.main_ledger_component .body_details .inner_body .details_con .btn_block div .send_remainder {
  background-color: #F9F07A;
}

.btn:focus {
  outline: none;
  border: none;
}

.form-control {
  background-color: transparent;
}

.form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: #cecece;
}

@media screen and (max-width: 991px) {
  .main_ledger_component .body_details .inner_body {
    width: 80%;
  }
  .main_ledger_component .body_details .inner_body .logo_container {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .main_ledger_component .body_details .inner_body {
    width: 90%;
  }
  .main_ledger_component .body_details .inner_body .logo_container {
    width: 100%;
  }
  .main_ledger_component .body_details .inner_body .amt_name {
    width: 60%;
    padding-left: 1rem;
  }
  .main_ledger_component .body_details .inner_body .amt_num {
    width: 40%;
    padding-right: 1rem;
  }
}

@media screen and (max-width: 388px) {
  .main_ledger_component .body_details .inner_body {
    width: 100%;
  }
  .main_ledger_component .body_details .inner_body .details_con .blocks {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .main_ledger_component .body_details .inner_body .details_con .blocks h4 {
    width: 50%;
    text-align: center;
  }
  .main_ledger_component .body_details .inner_body .details_con .blocks .principal_amt {
    position: relative;
  }
  .main_ledger_component .body_details .inner_body .details_con .blocks .principal_amt span {
    position: absolute;
    font-size: 12px;
    top: 0px;
  }
  .main_ledger_component .body_details .inner_body .details_con .blocks .amt_num {
    width: 40%;
  }
}
