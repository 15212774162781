.members_list{
    // background-color: #69d175;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .m_block{
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color:#f5be4a;
    }

    .arrow_block{
        width: 20%;
        background-color: #fff;
        text-align: center;
        font-weight: bold;
        cursor: pointer;
    }

}

.amt_input{
    label{
        width: 30%;
    }
}

.btn-close:focus{
    box-shadow: none !important;
    border: none;
    border-color: transparent;
}

input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}