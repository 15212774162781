.add_btn {
  width: 50px;
  height: 50px;
  color: #fff;
  font-weight: bolder;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

form .form-control {
  background-color: transparent;
}

form .form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: #cecece;
}

.main_outer_con {
  display: flex;
  justify-content: center;
  position: relative;
}

.main_outer_con .search_sec {
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_outer_con .cards_display {
  margin-top: 50px;
}

.btn-close:focus {
  box-shadow: none;
}

.theme_styles {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0px 8px 15px 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.note_card {
  width: 300px;
  padding-bottom: 10px;
  cursor: pointer;
}

.note_card .card-text {
  height: 220px;
  overflow: hidden;
}

pre {
  white-space: pre-wrap;
  word-break: break-word;
}

.btn-close:focus {
  box-shadow: none !important;
  border: none;
  border-color: transparent;
}

@media screen and (max-width: 913px) {
  .main_outer_con {
    justify-content: normal;
  }
  .main_outer_con .cards_display {
    width: 100%;
    padding: 15px;
    margin-top: 50px;
  }
  .main_outer_con .cards_display .note_card {
    padding-bottom: 10px;
  }
  .main_outer_con .cards_display .note_card .card-text {
    overflow: hidden;
  }
}

@media screen and (max-width: 415px) {
  .search_sec .note_search {
    width: 90% !important;
  }
}

@media screen and (max-width: 387px) {
  .theme_styles {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin: 0px 8px 20px 8px;
    cursor: pointer;
  }
  .note_card {
    width: 95%;
    padding-bottom: 10px;
  }
  .note_card .card-text {
    font-size: 15px;
    overflow: hidden;
  }
  .search_sec .note_search {
    width: 90% !important;
  }
}
