.login_div {
  width: 100vw;
  height: 90vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
}

.login_div .login_icon_logo {
  width: 120px;
  height: 120px;
  padding: 15px;
  font-size: 70px;
  background-image: url("../../../Assest/images/user-logo.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  color: blue;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #fff;
}

.login_div form .form-control {
  background-color: transparent;
}

.login_div form .form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: #cecece;
}

.login_div form .form-check-input {
  cursor: pointer;
}

.login_div form .form-check-input:focus {
  outline: none;
  box-shadow: none;
}

@media screen and (max-width: 400px) {
  .login_div {
    padding: 0px;
  }
  .login_div form {
    width: 100%;
    box-shadow: none;
  }
}

.f_p_input:focus {
  box-shadow: none !important;
}
