@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@600&family=Playpen+Sans:wght@500&family=Roboto:ital@1&display=swap');


.main_ledger_component{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    // background-color: #E8A9A9;
    // background-color: #D0D4CA;

    .ledger_header_block{
        //  background-color: cyan ;
        //  width: 108%;
         // padding-left: 45px;
         margin-top: 60px;
         display: flex;
         flex-direction: column;
        //  align-items: center;
        //  justify-content: center; 

    }

    .ledger_body_block{
        //  background-color: cyan ;
        // padding-left: 45px; 
        display: flex;
        flex-direction: column;
        // height: 50vh;
        // overflow: scroll;
    }


    .body_details{
        // background-color: antiquewhite;
        // margin-top: 25px;
        height: 70vh;

        .inner_body{
            width: 50%;
            // background-color: #0d6efd; 

            .logo_container{
                width: 100%;
                // background-color: #f5be4a;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding-bottom: 15px;

                img{
                    border-radius: 50%;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-color: #F1F0E8;
                }
                // .pro_pics_con{
                //     background-size: contain;
                //     background-color: #D0D4CA;
                // }
                p{
                    margin-top: 5px;
                    font-size: 18px;
                    font-weight: bold;
                    font-family: 'Oswald', sans-serif;
                    font-family: 'Playpen Sans', cursive;
                    font-family: 'Roboto', sans-serif;
                }

                div{
                    width: 180px;
                    height: 150px;
                    position: relative;
                    // background-color: #76c8f9;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .img_1{
                        position: absolute;
                        margin-right: 90px;
                    }
                    .img_2{
                        position: absolute;
                        margin-left: 90px;
                    }
                }
            }



            .details_con{
                // height: 50vh;
                // background-color: #f5be4a;

                .blocks{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    
                    // background-color: #98E4FF;

                    h4{
                        // width: 50%;
                        text-align: center;
                        font-size: 1.2rem;
                    }
                    .principal_amt{
                        position: relative;

                        span{
                            position: absolute;
                            font-size: 12px;
                            top: 0px;
                        }
                    }
                    .amt_name{
                        width: 70%;
                        border-right: none;
                        // background-color: yellow;
                        display: flex;
                        align-items: flex-start; 
                        padding-left: 2rem;

                    }
                    .amt_num{
                        width: 30%; 
                        // background-color: #0d6efd;
                        display: flex;
                        // justify-content: space-between;
                        padding-right: 2rem;
                    }

                }
                .btn_block{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    // background-color:aquamarine;
                    div{
                        width: 90%;
                        // background-color: beige;
                        display: flex;
                        justify-content: center;

                        button{
                            width: 100%;
                            font-weight: 600;
                        }

                        .add_trans{
                            background-color: #0d6efd;
                            background-color: #00A9FF;
                            color: #fff;
                        }

                        .view_history{
                            background-color: #D0D4CA;
                        }

                        .send_remainder{
                            background-color: #F9F07A;
                            // border: 2px solid rgba(255, 230, 0, 0.925);
                        }
                    }
                }
            }
        }
    }

}

.btn:focus{
    outline: none;
    border: none;
}

.form-control{
    background-color: transparent;
    &:focus{
        outline: none; 
        box-shadow: none;
        border-color: rgb(206, 206, 206);
    }
}

@media screen and (max-width: 991px) {
    
    .main_ledger_component{
        .body_details{
            // background-color: antiquewhite;
    
            .inner_body{
                width: 80%;
                // background-color: #0d6efd;
    
                .logo_container{
                    width: 100%;

                }
    
            }
        }
    }

}

@media screen and (max-width: 768px) {
    
    .main_ledger_component{
        .body_details{
            // background-color: antiquewhite;
    
            .inner_body{
                width: 90%;
                // background-color: #0d6efd;
    
                .logo_container{
                    width: 100%;

                }

                .amt_name{
                    width: 60%;
                    padding-left: 1rem;
                }
                .amt_num{
                    width: 40%;
                    padding-right: 1rem;
                }
    
            }
        }
    }

}

@media screen and (max-width: 575px){
    // .main_ledger_component{
    //     .ledger_body_block{
    //         height: 100vh;  
    //     } 

    // }
}

@media screen and (max-width: 388px) {
    
    .main_ledger_component{
        .body_details{
            // background-color: antiquewhite;
    
            .inner_body{
                width: 100%;
                // background-color: #0d6efd;
    
                .details_con{
                    // background-color: #f5be4a;
    
                    .blocks{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        
                        // background-color: #98E4FF;
    
                        h4{
                            width: 50%;
                            text-align: center;
                        }
                        .principal_amt{
                            position: relative;
    
                            span{
                                position: absolute;
                                font-size: 12px;
                                top: 0px;
                            }
                        }

                        .amt_num{
                            width: 40%;
                        }
    
                    }
                }
            }
        }
    }

}