
.main_navbar{
    width: 100% !important;
    position: fixed !important;
    z-index: 100;

    .toggle_nav_btn{
        display: none;
        // display: block;
    }

}

.navbar-toggler:focus{
    outline: none;
    box-shadow: none !important;
    border-color: none;
}


.profile_nav{
    border: 1px solid grey;
}

@media screen and (max-width: 991px) {
    .main_navbar{
        padding: 0;
    }
    .profile_nav{
        border:none
    }
    .main_navbar .nav_list_mob .nav-item{
        border-bottom: 1px solid rgba(175, 175, 175, 0.205);
        padding: 5px 0px;
    }
    .main_navbar .nav_list_mob li:last-child{
        border-bottom: none;
        padding: 5px 0px;
    }
    .main_navbar .nav_list_mob .mob_nav_items{
        flex-direction: column;
    }
}


// Home Navbar (inside)
.navbar_container{
    width: 100%;
    height: 50px;
    background-color: #f8f9fa;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 990;
    display: flex;
    justify-content: center;

    .inner_navbar{
        width: 94.5%;
        // background-color: greenyellow;
        height: inherit;
        display: flex;

        .brand_name{
            width: 50%;
            height: inherit;
            // background-color: orange;
            display: flex;
            align-items: center;


            .brand_image{
                width: 30px;
                height: 30px;
                // background-color:aliceblue;
                background-image: url("/public/favicon.ico");
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                margin-right: 5px;
                border-radius: 15px;
            }

            .more_btn_nav{
                width: 40px;
                height: 40px;
                // display: flex;
                display: none;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &:focus{
                    outline: none; 
                    border: none; 
                }
            }

        }
        
        .nav_items{
            width: 50%;
            // height: inherit;
            // background-color:coral;
            display: flex;
            align-items: center;

            ul{
                list-style-type: none;
            }

            .navbar_mobile{
                // display: none;
                display: flex;
                margin-left: auto;
            }
            
        }

    }

}

// Main Navbar (outside)
.more_btn_nav{
    cursor: pointer;
    padding: 0px 10px;
}

.home_nav_container{
    min-width: 100svw;
    min-height: 100svh;
    background-color: rgba(0,0,0,0.1);
    position: fixed;
    top: 0px;
    left: -100%;
    z-index: 999;
    // transition: all 0.4s linear ;
    transition: all 0.5s ease-in-out ;

    .inner_container{
        width: 20rem;
        height: 100svh;
        position: absolute;
        top: 0px;
        // left: -100%;
        background-color: #f8f9fa;
        transition:all 0.5s ease-in-out ; 

        .side_nav_icon{
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #D0D4CA;
            background-image: url('/public/favicon.ico');
            background-position: center; 
            background-repeat: no-repeat;
            background-size: contain;
        }

        ul{
            list-style: none;
            padding: 10px 20px;
            li{
                // background-color: #D0D4CA;
                margin: 5px 0px;
                padding: 10px 0px;
                
                a{
                    text-decoration: none;
                    font-weight: bold;
                    color: #000;
                    font-size: 1.3rem;
                    // background-color: pink;

                    &:hover{
                        color: #007bff;
                    }
                }
            }
        }
    }
    .close_nav_spacer{
        width: 100%;
        min-height: inherit;
        // background-color: orange;
    }

}

.lg_main_nav{
    width: 50%;
    list-style: none;
    li{
        // width: 20%;
        margin-left: 25px;

        a{
            text-decoration: none;
            color: #000;
            font-weight: 400;
            
            &:hover{
                color: #007bff;
            }
        }
    }
}


@media screen and (max-width: 991px){
    .navbar_container{
        
        .inner_navbar{
            position: relative;
            flex-direction: column;
            .brand_name{
                width: 100%;
                justify-content: space-between;
                z-index: 1000;
                background-color: #f8f9fa;
            
                .more_btn_nav{
                    display: flex;
                }
            }

            .nav_items{
                left: 0%;
                width: 100%;
                
                ul{
                    position: absolute;
                    top: -190%;
                    left: 0px;
                    // top: 100%;
                    transition: all 0.4s linear ;
                    z-index: 500;
                    background-color: #f8f9fa;
                    // background-color: pink;

                    width: 100%;
                    flex-direction: column;
                    // background-color: gray;
                    padding: 0px 10px;
                    li{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        // background-color: #f8f9fa;
                        padding: 5px 0px 1px 0px; 
                        
                        .logout_btn_nav{
                            width: 100%;
                            // background-color: bisque; 
                            padding: 5px 0px;
                        }
                    }
                }

                .navbar_mobile{
                    margin: 0px;
                    // background-color: yellow;

                    .mob_nav_items{
                        flex-direction: column;
                        // background-color: aqua;
                       
                        div{
                            width: 100%;
                            padding: 5px 0px;
                            // background-color: olivedrab;
                        }

                    }
                }
            }
            
            
        }
        
        .profile_nav{
            width: 100% !important;
        }
    }

    // outer navbar
    .home_nav_container{
    
        .inner_container{
    
            .mobile_nav_outer{
                list-style: none;
                padding: 10px 20px;
                li{
                    // background-color: #D0D4CA;
                    margin: 5px 0px;
                    padding: 10px 0px;
                    
                    a{
                        display: flex;
                        height: 100%;
                        text-decoration: none;
                        font-weight: bold;
                        color: #000;
                        font-size: 1.3rem;
                        // background-color: pink;
    
                        &:hover{
                            color: #007bff;
                        }
                    }
                }
            }
        }
    
    }
}

@media screen and (max-width: 430px){
 // Home Navbar
.home_nav_container{
    transition: all 0.5s ease-in-out ;
    .inner_container{
        width: 80svw;
    }

}
}