@import url("https://fonts.googleapis.com/css2?family=Wix+Madefor+Text:wght@800&display=swap");
.error_container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error_container .inner_container {
  width: 60%;
  height: 50%;
  text-align: center;
}

.error_container .inner_container h1 {
  font-weight: 1000;
  font-size: 10rem;
  font-family: 'Wix Madefor Text', sans-serif;
  text-align: center;
  background-image: url("../../../Assest/images/error_bg.jpg");
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Steelfish Rg', 'helvetica neue', helvetica, arial, sans-serif;
}

@media screen and (max-width: 768px) {
  .error_container .inner_container {
    width: 100%;
  }
  .error_container .inner_container h1 {
    font-size: 6rem;
  }
}
