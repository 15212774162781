.main_cards {
  width: 390px;
  height: 160px;
  margin-right: 25px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all .4s linear;
  background-position: 97% center;
  background-repeat: no-repeat;
  background-size: auto 50%;
  /* box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.5); */
  /* transform: scale(1.05); */
}

.main_cards .card_body {
  height: 150px;
}

.main_cards .card_body .card_text {
  width: 100%;
  text-wrap: balanced;
}

.main_cards .card_body .note_title {
  text-wrap: nowrap;
}

.main_cards .badge_icon {
  clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 15% 50%, 0% 0%);
}

.main_cards .more_opt {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: transparent;
}

.main_cards .more_opt:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.dash_cards {
  width: 390px;
  margin-right: 25px;
  margin-bottom: 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: all .4s linear;
}

.dash_cards:hover {
  z-index: 5;
  /* box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.5); */
  box-shadow: 0px 30px 18px -8px rgba(0, 0, 0, 0.1);
  /* transform: scale(1.05); */
  transform: scale(1.05, 1.05);
}

.dash_cards:hover ~ body {
  position: fixed;
}

.dash_cards h5 {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-toggle::after {
  display: none !important;
}

@media screen and (max-width: 881px) {
  .main_cards {
    margin-right: 0px;
  }
  .dash_cards {
    margin-right: 15px;
  }
}

@media screen and (max-width: 861px) {
  .main_cards {
    width: 92vw;
    margin-right: 0px;
  }
  .dash_cards {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 10px;
  }
}
