
.active{
    color: #000;
    text-decoration: none;
}

.btn:focus{
    box-shadow: none;
    border: none;
    border-color: transparent;
}

input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}