.add_btn {
  width: 50px;
  height: 50px;
  color: #fff;
  font-weight: bolder;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

form .form-control {
  background-color: transparent;
}

form .form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: grey;
}

form textarea:focus {
  border-color: grey;
}

.main_outer_con {
  display: flex;
  justify-content: center;
}

.main_outer_con .search_sec {
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_outer_con .search_sec div input:focus {
  outline: none;
  border-color: grey;
  box-shadow: none;
}

.main_outer_con .search_sec .dropdown-menu li {
  cursor: pointer;
}

.main_outer_con .search_sec .dropdown-menu li:hover {
  background-color: #EBF3E8;
}

.main_outer_con .search_sec .search_inner {
  width: 57%;
}

.main_outer_con .cards_display {
  width: 94.5%;
  margin-top: 70px;
  margin: 70px auto 0px auto;
}

.theme_styles {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0px 8px 15px 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-close:focus {
  box-shadow: none !important;
  border: none;
  border-color: transparent;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-select:focus {
  border-color: grey !important;
  box-shadow: none !important;
}

.form-check-input[type=checkbox]:focus {
  border-color: grey !important;
  box-shadow: none !important;
}

@media screen and (max-width: 913px) {
  .main_outer_con {
    justify-content: normal;
  }
  .main_outer_con .cards_display {
    width: 100%;
    padding: 15px;
    margin-top: 50px;
  }
}

@media screen and (max-width: 387px) {
  .theme_styles {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin: 0px 8px 20px 8px;
    cursor: pointer;
  }
}
