.main_profile_container{
    display: flex;
    justify-content: center;

    .inner_profile_container{
        // background-color: cyan ;
        width: 98%;
        // padding-left: 45px;
        margin-top: 55px;
        display: flex;
        align-items: center;
        justify-content: center;

        .profile_info{
            width: 100%;
            // background-color: greenyellow;

            .profile_pic_block{
                // background-color: pink;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }
            
            .pro_opt_con{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
             
                .pro_inner_opt_con{
                    width: 70%;
                    // background-color: aliceblue;
                    display: flex;
                    // justify-content: space-evenly;

                    .pro_options_settings{
                        width: 48%;
                        // background-color:grey;
                        display: flex;
                        justify-content: space-between;
                        font-weight: bold;
                        
                    }

                }

            }

        }

    }
}

.profile_pic_con{

    overflow: hidden;

    .overlay_profile_pic{
        width: 100%;
        height: 35%;
        background-color: rgba(0,0,0,0.4);
        bottom: 0%;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        cursor: pointer;
    }

}

.avatar_options{
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .avatar_pic{
        width: 50px;
        height: 50px;
        margin: 0px 15px 8px 0px;
        border-radius: 50%;
        background-color: rgba(128, 128, 128, 0.24);
        cursor: pointer;
        position: relative;

        .selected_avatar{
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            top: 0px;
            left: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(0,0,0,0.3);
            color: #fff;
        }

    }

}

.input_con{
    // background-color: pink;
    position: relative;
    
    .error_icon{
        position: absolute;
        top: 8%;
        right: 5%;
        cursor: pointer;
    }
}

.tooltip_style{
    background-color: red;
    color: #fff;
}

input[type=text]:focus{
    border-color: grey !important;
    box-shadow: none!important ; 
}
input[type=number]:focus{
    border-color: grey !important;
    box-shadow: none!important ; 
}
input[type=email]:focus{
    border-color: grey !important;
    box-shadow: none!important ; 
}
input[type=password]:focus{
    border-color: grey !important;
    box-shadow: none!important ; 
}

@media screen and (max-width: 400px) {
    .gender_container{
        .inner_gen_con{
           label{
                font-size:medium;
            }
        }
    }
}


@media screen and (max-width: 987px) {
    .main_profile_container{
    
        .inner_profile_container{
    
            .profile_info{
                .pro_opt_con{
                    .pro_inner_opt_con{
                        width: 100%;
                        .pro_options_settings{
                            width: 48%;                           
                        }
    
                    }
    
                }
    
            }
    
        }
    }
}

@media screen and (max-width: 600px) {
    .main_profile_container{
        .inner_profile_container{
            .profile_info{
                .pro_opt_con{
                    .pro_inner_opt_con{
                        width: 100%;
                        flex-direction: column;
    
                        .pro_options_settings{
                            // margin: 0px;
                            width: 95%;
                        }
    
                    }
    
                }
    
            }
    
        }
    }
}