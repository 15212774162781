.main_navbar {
  width: 100% !important;
  position: fixed !important;
  z-index: 100;
}

.main_navbar .toggle_nav_btn {
  display: none;
}

.navbar-toggler:focus {
  outline: none;
  box-shadow: none !important;
  border-color: none;
}

.profile_nav {
  border: 1px solid grey;
}

@media screen and (max-width: 991px) {
  .main_navbar {
    padding: 0;
  }
  .profile_nav {
    border: none;
  }
  .main_navbar .nav_list_mob .nav-item {
    border-bottom: 1px solid rgba(175, 175, 175, 0.205);
    padding: 5px 0px;
  }
  .main_navbar .nav_list_mob li:last-child {
    border-bottom: none;
    padding: 5px 0px;
  }
  .main_navbar .nav_list_mob .mob_nav_items {
    flex-direction: column;
  }
}

.navbar_container {
  width: 100%;
  height: 50px;
  background-color: #f8f9fa;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 990;
  display: flex;
  justify-content: center;
}

.navbar_container .inner_navbar {
  width: 94.5%;
  height: inherit;
  display: flex;
}

.navbar_container .inner_navbar .brand_name {
  width: 50%;
  height: inherit;
  display: flex;
  align-items: center;
}

.navbar_container .inner_navbar .brand_name .brand_image {
  width: 30px;
  height: 30px;
  background-image: url("/public/favicon.ico");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 5px;
  border-radius: 15px;
}

.navbar_container .inner_navbar .brand_name .more_btn_nav {
  width: 40px;
  height: 40px;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.navbar_container .inner_navbar .brand_name .more_btn_nav:focus {
  outline: none;
  border: none;
}

.navbar_container .inner_navbar .nav_items {
  width: 50%;
  display: flex;
  align-items: center;
}

.navbar_container .inner_navbar .nav_items ul {
  list-style-type: none;
}

.navbar_container .inner_navbar .nav_items .navbar_mobile {
  display: flex;
  margin-left: auto;
}

.more_btn_nav {
  cursor: pointer;
  padding: 0px 10px;
}

.home_nav_container {
  min-width: 100svw;
  min-height: 100svh;
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0px;
  left: -100%;
  z-index: 999;
  transition: all 0.5s ease-in-out;
}

.home_nav_container .inner_container {
  width: 20rem;
  height: 100svh;
  position: absolute;
  top: 0px;
  background-color: #f8f9fa;
  transition: all 0.5s ease-in-out;
}

.home_nav_container .inner_container .side_nav_icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #D0D4CA;
  background-image: url("/public/favicon.ico");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.home_nav_container .inner_container ul {
  list-style: none;
  padding: 10px 20px;
}

.home_nav_container .inner_container ul li {
  margin: 5px 0px;
  padding: 10px 0px;
}

.home_nav_container .inner_container ul li a {
  text-decoration: none;
  font-weight: bold;
  color: #000;
  font-size: 1.3rem;
}

.home_nav_container .inner_container ul li a:hover {
  color: #007bff;
}

.home_nav_container .close_nav_spacer {
  width: 100%;
  min-height: inherit;
}

.lg_main_nav {
  width: 50%;
  list-style: none;
}

.lg_main_nav li {
  margin-left: 25px;
}

.lg_main_nav li a {
  text-decoration: none;
  color: #000;
  font-weight: 400;
}

.lg_main_nav li a:hover {
  color: #007bff;
}

@media screen and (max-width: 991px) {
  .navbar_container .inner_navbar {
    position: relative;
    flex-direction: column;
  }
  .navbar_container .inner_navbar .brand_name {
    width: 100%;
    justify-content: space-between;
    z-index: 1000;
    background-color: #f8f9fa;
  }
  .navbar_container .inner_navbar .brand_name .more_btn_nav {
    display: flex;
  }
  .navbar_container .inner_navbar .nav_items {
    left: 0%;
    width: 100%;
  }
  .navbar_container .inner_navbar .nav_items ul {
    position: absolute;
    top: -190%;
    left: 0px;
    transition: all 0.4s linear;
    z-index: 500;
    background-color: #f8f9fa;
    width: 100%;
    flex-direction: column;
    padding: 0px 10px;
  }
  .navbar_container .inner_navbar .nav_items ul li {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px 0px 1px 0px;
  }
  .navbar_container .inner_navbar .nav_items ul li .logout_btn_nav {
    width: 100%;
    padding: 5px 0px;
  }
  .navbar_container .inner_navbar .nav_items .navbar_mobile {
    margin: 0px;
  }
  .navbar_container .inner_navbar .nav_items .navbar_mobile .mob_nav_items {
    flex-direction: column;
  }
  .navbar_container .inner_navbar .nav_items .navbar_mobile .mob_nav_items div {
    width: 100%;
    padding: 5px 0px;
  }
  .navbar_container .profile_nav {
    width: 100% !important;
  }
  .home_nav_container .inner_container .mobile_nav_outer {
    list-style: none;
    padding: 10px 20px;
  }
  .home_nav_container .inner_container .mobile_nav_outer li {
    margin: 5px 0px;
    padding: 10px 0px;
  }
  .home_nav_container .inner_container .mobile_nav_outer li a {
    display: flex;
    height: 100%;
    text-decoration: none;
    font-weight: bold;
    color: #000;
    font-size: 1.3rem;
  }
  .home_nav_container .inner_container .mobile_nav_outer li a:hover {
    color: #007bff;
  }
}

@media screen and (max-width: 430px) {
  .home_nav_container {
    transition: all 0.5s ease-in-out;
  }
  .home_nav_container .inner_container {
    width: 80svw;
  }
}
