.login_div{
    width: 100vw;
    height: 90vh;
    // background-image: url("../../../Assest/images/login-bg.jpeg");
    // background-image: url("../../../Assest/images/login2-bg.jpg");
    // background-image: url("../../../Assest/images/bg-dark.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center; 

    .login_icon_logo{
        width: 120px;
        height: 120px;
            padding: 15px;
            font-size: 70px;
            // background-color: blue;
            background-image: url("../../../Assest/images/user-logo.png");
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            color: blue;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: 1px solid #fff;
        

    }

    form{
        // background-color: rgba(0, 0, 0, 0.5); 
        // color: #fff;
        // background-color: #fff;
        // color: #000;

        .form-control{
            background-color: transparent;
            &:focus{
                outline: none;
                box-shadow: none;
                border-color: rgb(206, 206, 206);
            }
        }
        
        .form-check-input{
            cursor: pointer;
            &:focus{
                outline: none;
                box-shadow: none;
                // border-color: rgb(206, 206, 206);
            }
        }


    }
  }

  @media screen and (max-width: 400px){
    .login_div{
        padding: 0px;
        form{
            width: 100%;
            box-shadow: none;
        }
      }
  }
    
  
//   forgot password 
.f_p_input{
    // border-color: #dee2e6 !important;
    &:focus{
        // border-color: grey !important;
        box-shadow: none !important;

    }
}
