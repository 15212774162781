.add_btn{
    width: 50px;
    height: 50px;
    color: #fff;
    font-weight: bolder;
    font-size :40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

form{

    .form-control{
        background-color: transparent;
        &:focus{
            outline: none; 
            box-shadow: none;
            border-color: rgb(206, 206, 206);
        }
    }


}


.main_outer_con{
    display: flex;
    justify-content: center;
    // position: relative;
    // padding-bottom: 50px;

    .search_sec{
        // width: 100%;
        // height: 50px;
        // padding: 5px 0px;
        z-index: 99;
        // position: fixed !important;
        // top: 56px;
        display: flex;
        // margin-top: 50px;
        align-items: center;
        justify-content: center;

        
        div{
            // width: 50%;
            // background-color: orange;
            
            input:focus{
                outline: none;
                border-color: rgb(206, 206, 206); 
                box-shadow: none;
            }

        }
        .dropdown-menu{
            li{
                cursor: pointer;
                &:hover{
                    background-color: #EBF3E8;
                }
            }
        }

        .search_inner{
            width: 80%;
        }
    }

    .cards_display{
        // width: 94.5%;
        // padding-left: 45px;
        margin-top: 70px;
    }
}


.theme_styles{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0px 8px 15px 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-close:focus{
    box-shadow: none !important;
    border: none;
    border-color: transparent;
}

input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@media screen and (max-width: 913px){

    .main_outer_con{
        justify-content: normal;
        .cards_display{
            width: 100%;
            padding: 15px;
            margin-top: 50px;
        }
    }
}

@media screen and (max-width: 387px){

    .theme_styles{
        width: 45px;
        height: 45px;
        border-radius: 50%;
        margin: 0px 8px 20px 8px;
        cursor: pointer
    }

    .search_inner{
        width: 80% !important;
    }

}