.add_btn {
  width: 50px;
  height: 50px;
  color: #fff;
  font-weight: bolder;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

form .form-control {
  background-color: transparent;
}

form .form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: #cecece;
}

.main_outer_con {
  display: flex;
  justify-content: center;
}

.main_outer_con .search_sec {
  width: 100%;
  z-index: 99;
  top: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_outer_con .search_sec div input:focus {
  outline: none;
  border-color: #cecece;
  box-shadow: none;
}

.main_outer_con .search_sec .dropdown-menu li {
  cursor: pointer;
}

.main_outer_con .search_sec .dropdown-menu li:hover {
  background-color: #EBF3E8;
}

.main_outer_con .search_sec .search_inner {
  width: 57%;
}

.main_outer_con .cards_display {
  padding-left: 45px;
  margin-top: 70px;
}

.btn-close:focus {
  box-shadow: none !important;
  border: none;
  border-color: transparent;
}

.theme_styles {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0px 8px 15px 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reg_num_con div {
  width: 80%;
}

.reg_num_con button {
  width: 18%;
}

@media screen and (max-width: 913px) {
  .main_outer_con {
    justify-content: normal;
  }
  .main_outer_con .cards_display {
    width: 100%;
    padding: 15px;
    margin-top: 50px;
  }
}

@media screen and (max-width: 387px) {
  .theme_styles {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin: 0px 8px 20px 8px;
    cursor: pointer;
  }
  .main_outer_con {
    display: flex;
    justify-content: center;
  }
  .main_outer_con .search_sec div {
    width: 70%;
  }
}
