.add_btn {
  width: 50px;
  height: 50px;
  color: #fff;
  font-weight: bolder;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

form .form-control {
  background-color: transparent;
}

form .form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: #cecece;
}

.main_outer_con {
  display: flex;
  justify-content: center;
}

.main_outer_con .search_sec {
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_outer_con .home_cards_display {
  width: 98%;
  padding-left: 45px;
  margin-top: 50px;
}

.theme_styles {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0px 8px 15px 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home_left_bg {
  width: 300px;
  height: 300px;
  top: -50%;
  left: 10%;
}

@media screen and (max-width: 913px) {
  .main_outer_con {
    justify-content: normal;
  }
  .main_outer_con .home_cards_display {
    width: 100%;
    padding: 15px;
    margin-top: 50px;
  }
}

@media screen and (max-width: 387px) {
  .theme_styles {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin: 0px 8px 20px 8px;
    cursor: pointer;
  }
}
