// @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@600&family=Playpen+Sans:wght@500&family=Roboto+Slab:wght@700&family=Roboto:ital@1&display=swap');

.about_container{
    // background-color: #FFFBF5;
    background-color: #fdfdfd;
    padding-top: 70px;
}
.about_headings{
    color: #8EACCD;
    font-weight: bolder;
    // font-family: 'Oswald', sans-serif;
    // font-family: 'Playpen Sans', cursive;
    // font-family: 'Roboto', sans-serif;
    // font-family: 'Roboto Slab', serif;
    h1{
        color: #363062;
    }
}

.underline_sap{
    width: 150px;
    height: 2px;
    background-color: #8EACCD;
    background-color: #363062;
    margin: 5px auto;
}

.reasons_cards{
    width: 400px; 
    height: 200px;
    margin: 30px 0px;
    // background-color: yellow;
    
    div{
        img{
            width: 55px;
            height: 55px;  
        }
    }
}

.vision_mission_card{
    img{
        width: 100px;
        height: 100px;
    }
}

// steps blogs
.steps_blogs{
    // display: flex;

    .step_info{
        h3{
            span{
                color: #818FB4;
                border-left: 2px solid #818FB4;
                padding-left: 10px;
            }
        }
    }


}



.image_con{
    width: 80%;
    height: 350px;
    // background-color: orange;  
    // background-image: url("../../../Assest/aboutImages/laptop_frame.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size:contain;
} 

.img_1{
    background-image: url("../../../Assest/aboutImages/register.gif");
}

.img_2{
    background-image: url("../../../Assest/aboutImages/login.gif");
}

.img_3{
    background-image: url("../../../Assest/aboutImages/create_ledger.gif");
}

.img_4{
    background-image: url("../../../Assest/aboutImages/addTransaction.gif");
}

.img_5{
    background-image: url("../../../Assest/aboutImages/edit_share.gif");
}

.img_6{
    background-image: url("../../../Assest/aboutImages/active_deactive.gif");
}

.img_7{
    background-image: url("../../../Assest/aboutImages/delete.gif");
}

// meet me 
.about_me{
    height: 200px;
    // margin: 30px 0px;
    margin: 90px 0px;
    
    .about_me_img_con{
        position: relative;  

        .my_img{
            width: 300px;
            height: 350px;
            // border-radius: 50%;
            background-image: url("../../../Assest/aboutImages/monish.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            // border-radius: 30% 70% 70% 30% / 30% 53% 47% 70% ;
            position: absolute;
            z-index: 50;
            // background-color: pink;
            bottom: 0px;
        }
        .img_background{
            width: 250px;
            height: 230px;
            position: absolute;
            z-index: 20;
            background-color: orange;
            border-radius: 30% 70% 70% 30% / 30% 53% 47% 70% ;
            bottom: 0px;
    
        }
    }

    .links_container{
        // background-color: pink;
        .social_links{
            width: 30px;
            height: 30px;
            padding: 0px;
            // background-color: #252525;
            // color: orange;
            border-radius: 50%;
            display: grid;
            place-items: center;
            font-size: larger;
            margin: 0px 5px;
            
            &:hover{
                background-color: orange;
            }
        }
    }

}

@media screen and (max-width: 991px) {
    .steps_blogs{
        // display: block;

        .step_info{
            text-align: center;
            // padding: 0px 10px;
            margin-top: 15px;

            h3{
                span{
                    color: #8EACCD;
                    border-left: none;
                    // padding-left: 10px;
                }
            }
            p{
                text-align: center;
            }
        }
    }
    .steps_blogs_even{
        display: flex;
        flex-direction: column-reverse;
    }
    .image_con{
        width: 100%;
    }

    
    // meet me 
    .about_me{
        height: 100%;
        margin: 100px 0px;
        text-align: center;
        margin-bottom: 0px;

        .about_me_img_con{
            height: 250px;
            margin-bottom: 15px;

            .my_img{
                // width: 250px;
                // height: 250px;
                // border-radius: 30% 70% 70% 30% / 30% 53% 47% 70% ;
                // margin-bottom: 20px;
            }
        }

        .links_container{
            justify-content: center;
            text-align: center;
        }

    }

}

@media screen and (max-width: 847px) {

    // reasons blocks
    .reasons_cards{
        margin: 50px 0px;
        div{

            text-align: center;

            img{
                width: 105px;
                height: 105px;
                margin-bottom: 10px;
            }
        }
    }

    // meet me 


}

// @media screen and (max-width: 575px) {
@media screen and (max-width: 480px) {
    .steps_blogs{
        // display: block;
        // background-color: pink;

        .step_info{
            text-align: center;
            // padding: 0px 10px;
            margin-top: 15px;
        }
    }
    .steps_blogs_even{
        display: flex;
        flex-direction: column-reverse;
    }
    .image_con{
        // background-color: orange;
        width: 100%;
    }

    .img_1{
        background-image: url("../../../Assest/aboutImages/m_s_1.gif");
    }
    
    .img_2{
        background-image: url("../../../Assest/aboutImages/m_s_2.gif");
    }
    
    .img_3{
        background-image: url("../../../Assest/aboutImages/m_s_3.gif");
    }
    
    .img_4{
        background-image: url("../../../Assest/aboutImages/m_s_4.gif");
    }
    
    .img_5{
        background-image: url("../../../Assest/aboutImages/m_s_5.gif");
    }
    
    .img_6{
        background-image: url("../../../Assest/aboutImages/m_s_6.gif");
    }
    
    .img_7{
        background-image: url("../../../Assest/aboutImages//m_s_7.gif");
    }

}